.banner {
  background-color: $white;
  min-height: 95px;
  position: relative;
  z-index: 10;
  -webkit-box-shadow: 0px 4px 9px -5px rgba(0,0,0,0.8);
  -moz-box-shadow: 0px 4px 9px -5px rgba(0,0,0,0.8);
  box-shadow: 0px 4px 9px -5px rgba(0,0,0,0.8);

  > .container {

    > .row {
      position: relative;
    }

  }

  &__utils {
    text-align: right;
  }

  &__social {
    margin-top: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: auto;
    }
    a {
      display: inline-block;
      vertical-align: middle;

      img {
        width: 30px;
        height: auto;
      }
    }
  }

  &__logo {
    @include make-col-ready();
    position: relative;
    @include make-col(8);
    @include media-breakpoint-up(sm) {
      @include make-col(7);
    }
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    a {
      display: block;
      img {
        z-index: 100;
        width: 100%;
        height: auto;
        @include media-breakpoint-up(md) {
          position: absolute;
          top: 0;
          left: 15px;
          max-width: 150px;
        }
      }

    }
  }

  &__nav {
    @include make-col-ready();
    @include make-col(16);
    @include media-breakpoint-up(sm) {
      @include make-col(17);
    }
    @include media-breakpoint-up(md) {
      @include make-col(20);
      padding-left: 30px;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(21);
    }

  }

  &__languages {
    display: inline-block;
    text-align: left;
    margin-top: 10px;
    vertical-align: middle;
    width: 100%;
    @include media-breakpoint-up(sm) {
      padding-left: 15px;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: auto;
    }

    .wpml-ls-legacy-dropdown-click a {
      height: 30px;
    }
  }

  &__search {
    display: none;
    text-align: left;
    margin-top: 10px;
    vertical-align: middle;
    padding-right: 15px;
    // border-right:1px solid #ced4da;
    height: 36px;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: auto;
    }

    &--form {

      .search-field {
        border-radius: 0;
        padding: 5px 10px;
        line-height: 1;
        height: 30px;
        margin-top: 3px;
      }

      .search-submit {
        border-radius: 0;
        padding: 5px 10px;
        line-height: 1;
        display: none;
      }

      .search-form .search-field {
        width: 100%;
      }

    }

  }

}



.navbar {
  position: relative;
  padding:7px 15px;
  width: 100%;
  // text-align: center;
  margin-bottom: 0px;
  // @include make-col-ready();
  // text-align: center;
  // display: flex;
  // justify-content: center;
  font-weight: $font-weight-normal;


  @include media-breakpoint-up(md) {
    margin-bottom: 0px;
  }
  @include media-breakpoint-up(xl) {
    padding: 6px 8px 8px 8px;
    // @include make-col(10);
  }

  .navbar-nav {

    > li {
      @extend .nav-item;
      margin: 0;
      border-bottom: 1px solid #ccc;
      @include media-breakpoint-up(xl) {
        margin: 0 10px;
        border-bottom-width: 0;
      }

      &.active {
        > a {
          color: #a00000;
          // text-transform: uppercase;
          border-bottom: 1px solid #a7b5c0;
          @include media-breakpoint-up(xl) {
            border-bottom: 1px solid #a7b5c0;
          }
        }
      }

      > a {
        @extend .nav-link;
        color: #192145;
        display: block;
        @include media-breakpoint-up(xl) {
          display: block;
          border-bottom: 1px solid #fff;
        }

        &:hover, &:focus, &:active {
          @include media-breakpoint-up(xl) {
            border-bottom: 1px solid #a7b5c0;
          }
        }
      }

      &.lastitem {
        .dropdown-menu {
          right: 0;
          left: auto;
        }
      }

      .dropdown-menu {
          padding: 0;
          text-align: left;
          border:none;
          @include media-breakpoint-up(xl) {
            border: 1px solid rgba(0,0,0,.15);
          }

          .menu-item {

            border-bottom: 1px solid rgba(0,0,0,.15);
            @include media-breakpoint-up(xl) {
              border-bottom: none;
            }

            a {
              @extend .dropdown-item;
              padding: 0.25rem .5rem;
              white-space: normal;
              @include media-breakpoint-up(xl) {
                white-space: nowrap;
              }
              // line-height: 1.1;
              &:active {
                background-color: $gray-200;
                color: #000;
              }
            }

            &.active {
              a {
                background-color: $gray-200;
                color: #000;
              }
            }


          }
      }
    }

    > .menu-item {
      @extend .nav-item;
      margin: 0;
      border-bottom: 1px solid #ccc;
      @include media-breakpoint-up(xl) {
        margin: 0 7px;
        border-bottom-width: 0;
      }

      &.show {
        background-color: $gray-100;
      }

      &.active {
        > a {
          color: #a00000;
          background-color: $gray-200;
          border-bottom: none;
          @include media-breakpoint-up(xl) {
            background-color: transparent;
            border-bottom: 1px solid #a7b5c0;
          }

          .search-results & {
            color: inherit;
            border-bottom: none;
          }
        }
      }

      > a {
        @extend .nav-link;
        color: #192145;
        display: block;
        padding: 2px 5px;
        @include media-breakpoint-up(xl) {
          display: block;
          border-bottom: 2px solid #fff;
        }

        &:hover, &:focus, &:active {
          @include media-breakpoint-up(xl) {
            border-bottom: 1px solid #a7b5c0;
          }
        }

        &.dropdown-toggle {
          &:after {
            margin-left: 0;
          }
        }
      }

    }

  }

}

.nav-primary {
  background-color: $white;
  margin: 0 auto;
  @include media-breakpoint-up(xl) {
    margin: 0;
    background-color: transparent;
  }
}

.navbar-toggler {
  width: 100%;
  border-radius: 0;
  text-align: left;
  // border-left-width: 0px;
  // border-right-width: 0px;
  background-color: $white;
  border-color: #ccc !important;

  &:focus {
    outline: 0;
  }

}

.navbar-menutext {
  display: inline-block;
  vertical-align: middle;
  @include rhythm(16,20);
}

.navbar-collapse {
  flex-basis: auto;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 100%;
  @include media-breakpoint-up(xl) {
    border-left: none;
    border-right: none;
    flex-grow: 0;
  }
}

.navbar-expand-xl .navbar-nav > .menu-item > a {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  @include media-breakpoint-up(xl) {
    padding-left: 0;
    padding-right: 0;
    padding-top:0;
    padding-bottom:0;
  }
}

.containder-slides {
  @extend .container-fluid;
  padding-left: 0;
  padding-right: 0;
}

.slider {

  max-width: 1170px;
  margin: 0 auto;

  .home & {
    max-width: 100%;
    img {
      max-height: 100%;
      width: 100%;
    }
  }

  &--padder {

    .slick-slide {
      padding-bottom: 20px;
      @include media-breakpoint-up(md) {

        padding-bottom: 60px;
      }
    }

  }

  img {
    margin: 0 auto;
    max-height: 692px;
  }

}

.head-image {
  width: 100%;
}
