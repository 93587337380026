.frontpage {

  &__ingress {

    padding-top: 35px;
    padding-bottom: 35px;

    h1, h2, h3, h4, h5 {
      @include rhythm(24,26);
      font-weight: $font-weight-bold;
      margin-bottom: 25px;
      margin-top: 0;
      padding-top: 0;
      @include media-breakpoint-up(md) {
        @include rhythm(28,30);
      }
    }

    p {
      font-weight: $font-weight-light;
      @include rhythm(18,22);
      @include media-breakpoint-up(md) {
        @include rhythm(22,26);
      }
    }

  }

  &__content {
    background: -moz-linear-gradient(top,  rgba(168,36,36,0.6) 0%, rgba(97,21,21,0.6) 100%);
    background: -webkit-linear-gradient(top,  rgba(168,36,36,0.6) 0%,rgba(97,21,21,0.6) 100%);
    background: linear-gradient(to bottom,  rgba(168,36,36,0.6) 0%,rgba(97,21,21,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80a82424', endColorstr='#80611515',GradientType=0 );

    padding: 30px 0;

    &--text {
      color: $white;
      font-weight: $font-weight-light;

      h1, h2, h3, h4, h5 {
        @include rhythm(26,30);
        font-weight: $font-weight-normal;
        margin-bottom: 25px;
        margin-top: 0;
        padding-top: 0;
      }

      p {
        @include rhythm(16,22);
      }
    }

  }

  &__facebook {

    &--text {
      margin-top: 30px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
      color: $white;
      font-weight: $font-weight-light;

      h1, h2, h3, h4, h5 {
        @include rhythm(18,22);
        font-weight: $font-weight-normal;
        margin-bottom: 15px;
        margin-top: 0;
        padding-top: 0;
      }
    }

  }
}

.linkrow {
  background-color: rgba(255,255,255,0.6);
  margin-bottom: $grid-gutter-width / 2;
  padding-top: $grid-gutter-width / 2;
  padding-bottom: $grid-gutter-width / 2;

  .linklogo {
    text-align: center;
    margin-bottom: $grid-gutter-width / 2;

    img {
      max-width: 200px;
      max-height: 150px;
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }

  .col {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.search-item {
  padding: 7px 10px 22px;
  border: 1px solid $gray-500;
  border-radius: 2px;
  background-color: rgba(255,255,255,0.5);
  margin-bottom: 5px;
  position: relative;

  a {
    color: $black;
  }

  span {
    @include rhythm(12,12);
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 3px 3px 10px;
    width: auto;
    background-color: hsl(0, 9.9%, 64.3%);
  }
  .entry-title {
    margin: 0 0 5px 0;
    padding: 0;
    position: relative;
    @include rhythm(18,20);

  }

  .entry-summary {
    @include rhythm(16,16);
    p:last-child {
      margin-bottom: 0;
    }
  }

}
