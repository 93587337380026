.footer {
  background-color: $white;
  padding-top: 20px;
  font-weight: $font-weight-normal;

  .imgwrap {
    padding: 15px;
    @include media-breakpoint-up(md) {
      padding: 30px;
    }

    img {
      max-width: 175px;
    }
  }

  &__text {
    text-align: center;
    @include rhythm(14,18);
    padding: 10px 15px 50px;

    p {
      margin-bottom: 0;
    }
  }
}
