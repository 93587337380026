.gallery {
  // padding-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width / 2;

  @include media-breakpoint-up(sm) {
    margin-bottom: $grid-gutter-width;
  }
  // margin-left: 0;
  // margin-right: 0;


  .card {
    background-color: rgba(255,255,255,0.6);
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-bottom: $grid-gutter-width / 3;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width / 2;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width;
    }

    &:hover, &:focus {
      border: 1px solid rgba(0,0,0,0.75);
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }

    .card-body {
      padding: 5px;
      @include rhythm(12,14);
      text-align: center;
      @include media-breakpoint-up(sm) {
        @include rhythm(14,18);
      }
    }

    figure {
      margin: 0;
      display: inline;
    }

    .card-text {
      margin-bottom: 0;
    }

  }

  .cols {

    @include make-col-ready();
    padding-left: 4px;
    padding-right: 4px;
    @include media-breakpoint-up(sm) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include media-breakpoint-up(md) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &.columns-1 {
      @include make-col(24);
    }

    &.columns-2 {
      @include make-col(12);
    }

    &.columns-3 {
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
    }

    &.columns-4 {
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    &.columns-5 {
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4.8);
      }

    }

    &.columns-6 {
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

    }

    &.columns-7 {
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(3.4);
      }

    }

    &.columns-8 {
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }

    }

    &.columns-9 {
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(2.66);
      }

    }

  }

  .pinbutton {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    @include media-breakpoint-up(md) {
      top: 5px;
      left: 5px;
    }
  }

}

.gallerysidebar {

  @include media-breakpoint-down(md) {

    margin-left: 0;
    margin-right: 0;
  }

  .cols {
    a {
      display: block;
      padding: 3px;

      img {
        border: 3px solid rgba(255,255,255,0.6);
        -webkit-transition-duration: 0.6s;
        -moz-transition-duration: 0.6s;
        -o-transition-duration: 0.6s;
        transition-duration: 0.6s;
      }

      &:hover, &:focus {

        img {
          border: 3px solid rgba(138, 53, 53,0.9);
        }


      }
    }
  }
}

// .single, .page {
//   .wrap {
//     .gallery {
//       // float:left;
//       clear:both;
//       width: 100%;
//       margin-top: 20px;
//       .gallery-image {
//       }
//       .thumbnail {
//         margin-bottom: 15px;
//       }
//       .caption {
//         font-size: 0.875em;
//         padding: 5px 0 0 0;
//         line-height: 1.2em;
//         text-align: center;
//       }
//     }
//   }
// }


// .page-template-template-kuvagalleria {

//   .page-header {
//     margin: 0 0 5px 0;
//     padding: 0 10px 5px;
//   }

//   .wrap {
//       .kuvagalleria {
//         @media (min-width: $screen-md-min) {
//          // margin-left: 1%;
//         }
//           padding-bottom: 17px;
//         .grid-sizer {
//           width:50%;
//           @media (min-width: $screen-sm-min) {
//             width:25%;
//           }
//           @media (min-width: $screen-md-min) {
//             width:20%;
//           }
//         }
//         .gallerythumb {
//           text-align: left;
//           float:left;
//           width:50%;
//           padding: 1%;
//           @media (min-width: $screen-sm-min) {
//             width:25%;
//           }
//           @media (min-width: $screen-md-min) {
//             width:20%;
//           }
//           &:hover {
//             -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
//             -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
//             box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
//           }
//           .img-responsive {
//             display: inline;
//             border:1px solid #ccc;
//           }
//           a {
//             margin: 0 5px 5px 0;
//             float: left;
//             clear: left;
//             width:100%;
//           }
//           span {
//             font-size: 1em;
//             float: left;
//             line-height: 1.3;
//             padding-top: 0;
//             width: 100%;
//             word-break: break-word;
//           }
//         }
//       }
//   }
// }
// .attachment {
//   .wrap {
//       a {
//         color:#009dde;
//         font-weight: 700;
//         span {
//           color:#ff7500 !important;
//         }
//       }
//       .gallerysidebar {
//         // margin-left: 5px;
//         padding: 10px 0;
//         // padding-left: 17px;
//         .grid-sizer {
//           width:33.2%;
//           @media (min-width: $screen-sm-min) {
//             width:24.9%;
//           }
//           @media (min-width: $screen-md-min) {
//             width:49.9%;
//           }
//         }
//         .gallerysidebarthumb {
//           float:left;
//           padding: 4px 2px 0 2px;
//           width:33.2%;
//           @media (min-width: $screen-sm-min) {
//             width:24.9%;
//           }
//           @media (min-width: $screen-md-min) {
//             width:49.9%;
//           }
//           &:hover {
//           }
//         }
//       }
//       .returnback {
//         margin:10px 0;
//         text-transform: uppercase;
//       }
//       .img-responsive {
//         display: block;
//         max-width: 100%;
//         max-height: 700px;
//         height: auto;
//         width: auto;
//         margin: 0 auto;
//       }
//       .attachmentwrap {
//         color:#009dde;
//         text-align: center;
//         h1{
//           font-size:18px;
//           font-weight: 600;
//           // text-transform: uppercase;
//           margin-top: 10px;
//           margin-bottom: 5px;
//         }
//         h3 {
//           font-size:16px;
//           margin-bottom: 5px;
//           font-weight: 600;
//           // text-transform: uppercase;
//         }
//         p {
//           font-size:14px;
//         }
//         a {
//           font-weight: 400;
//         }
//       }
//   }
// }
// // attachment social
// #socialbox {
//   a {
//     color:#009dde;
//     font-weight: 600;
//     // line-height: 34px;
//     font-size:13px;
//     span {
//       display:none;
//       color:#009dde !important;
//       @media (min-width: $screen-sm-min) {
//         display:inline;
//       }

//     }
//   }
//   p {
//     text-align:center;
//   }
//   // float: left;
//   &.videobox {
//     margin-top:-10px;
//     margin-left:15px;
//   }
//   &.attachment {
//     width: 100%;
//     overflow: hidden;
//     line-height: 15px;
//     margin: 10px;
//     ul {
//       list-style: none;
//       position: relative;
//       float: left;
//       display: block;
//       left: 50%;
//       padding:0px !important;
//       margin-top:0px;
//       margin-bottom:0px;
//       margin-left:0px;
//       li {
//         position: relative;
//         float: left;
//         display: block;
//         right: 50%;
//         &.pinit {
//           margin-right:50px;
//           // margin-top: 6px;
//         }
//         &.fb {
//           // margin-top: 6px;
//           float:left;
//           margin-right: 20px;
//           @media (max-width: 415px) {
//             // display:none;
//           }
//         }
//         &.gplus {
//           // margin-top: 6px;
//           float:left;
//           margin-right: 0px;
//           @media (max-width: 475px) {
//             display:none;
//           }
//         }
//         &.twit {
//           // margin-top: 6px;
//           float:left;
//           margin-right: 10px;
//           @media (max-width: 475px) {
//             display:none;
//             margin-right: 0px;
//           }
//         }
//         &.previous-link {
//           margin-right:10px;
//           @media (min-width: $screen-sm-min) {
//             margin-right:25px;
//           }
//         }
//         &.next-link {
//           margin-right:10px;
//           @media (min-width: $screen-sm-min) {
//            margin-left:25px;
//           }
//         }
//       }
//     }
//   }
//   .fb_edge_comment_widget { display: none !important; }
// }

// #wpadminbar #wp-admin-bar-edit-gallery .ab-icon:before {
//     content: "\f464";
//     top: 2px;
// }


// .pinitthumb {
//     /* position: absolute; */
//     margin-top: -30px;
//     margin-left: 5px;
//     position: relative;
//     float: left;
//     width: 40px;
// }
