body#tinymce {
  margin: 12px !important;

  h1 {
    @include rhythm(26,30);
    font-weight: $font-weight-normal;
    padding-top: 15px;
    margin-bottom: 20px;
  }

  h2 {
    @include rhythm(24,28);
    font-weight: $font-weight-normal;
    padding-top: 15px;
    margin-bottom: 20px;
  }

  h3 {
    @include rhythm(22,26);
    font-weight: $font-weight-normal;
    padding-top: 10px;
    margin-bottom: 17px;
  }

  h4 {
    @include rhythm(20,24);
    font-weight: $font-weight-normal;
  }

  h5 {
    @include rhythm(18,22);
    font-weight: $font-weight-normal;
  }

}
