.attachment {
  .wrap {
    padding-top: 15px;
    @include media-breakpoint-up(md) {

      padding-top: 30px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 50px;
    }
  }
}


.kohde {

  &__details {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0 0;
    padding: 20px;
    background-color: rgba(255,255,255,0.6);
    color: $black;
    word-wrap: break-word;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }


    li {
      margin: 2px 0;

      &.details-maplink {
        margin-bottom: 15px;

        a {
          color: $black;
        }
      }

      &.details-title {
        @include rhythm(20,24);
        font-weight: $font-weight-normal;
        margin-bottom: 6px;
      }

      &.details-address {
        margin-bottom: 12px;

      }

      &.details-content {
        margin: 10px 0 0 0;
      }

      &.details-social {
        margin: 10px 0 0 0;
        a {
          display: inline-block;
          margin-right: 7px;
          border: 1px solid #ccc;
        }
      }

    }

  }

}


.wrap-videos {
  // @extend .container-fluid;
  background-color: rgba(137,138,188,0.22);
  padding-top: 40px;
  padding-bottom: 0px;
  text-align: center;

  .video-title {
    @include rhythm(24,28);
    font-weight: $font-weight-normal;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .embed-responsive {
    margin-bottom: 40px;
  }
}
