body.media {
  display: block !important;
}

.slidebg {
  background: #c7ac83 url('../images/gingerpaperbg.jpg') no-repeat top center;


  #summerstyle & {
      background: #a4bb86 url('../images/gingerpaperbg.jpg') no-repeat top center;
  }
}

.home {

  .wrap--slider {
    background: #c7ac83 url('../images/gingerpaperbg.jpg') no-repeat top center;
    margin-top: 0px;
    padding-top: 20px;

    #summerstyle & {
      background: #a4bb86 url('../images/gingerpaperbg.jpg') no-repeat top center;
    }

  }
}

.wrap {
  background: #c7ac83 url('../images/gingerpaperbg.jpg') no-repeat top center;
  padding-bottom: 15px;
  word-wrap: break-word;
  @include media-breakpoint-up(md) {
    padding-bottom: 35px;
  }
  @include rhythm(18,26);
  background-size: cover;

  @include media-breakpoint-up(lg) {
    background-size: auto;
  }

  #summerstyle & {
    background: #a4bb86 url('../images/gingerpaperbg.jpg') no-repeat top center;
  }

  &--postimage {
    padding-top: 80px;
  }
  &--slider {
    background: #c7ac83 url('../images/paperbg.jpg') no-repeat top center;
    margin-top: -20px;
    padding-top: 50px;
    @include media-breakpoint-up(sm) {
      margin-top: -20px;
      padding-top: 30px;
    }
    @include media-breakpoint-up(md) {
      margin-top: -60px;
      padding-top: 80px;
    }

    #summerstyle & {
      background: #a4bb86 url('../images/gingerpaperbg.jpg') no-repeat top center;
    }


  }

  h1 {
    @include rhythm(26,30);
    font-weight: $font-weight-normal;
    padding-top: 15px;
    margin-bottom: 20px;
  }

  h2 {
    @include rhythm(24,28);
    font-weight: $font-weight-normal;
    padding-top: 15px;
    margin-bottom: 20px;
  }

  h3 {
    @include rhythm(22,26);
    font-weight: $font-weight-normal;
    padding-top: 10px;
    margin-bottom: 17px;
  }

  h4 {
    @include rhythm(20,24);
    font-weight: $font-weight-normal;
  }

  h5 {
    @include rhythm(18,22);
    font-weight: $font-weight-normal;
  }

}

.alert {
  margin-bottom: 0;
}

// TRIPADVISOR WIDTH

.widSSP {
  width: 100% !important;
}

.noslider {
  position: relative;

  max-width: 1170px;
  margin: 0 auto;
}

#map {
  min-height: 400px;
  // height: 100%;
  margin-bottom: 25px;
}

.titles {

  &--ribbon {

    div {

      display: flex;
      flex-direction: row;
      justify-content: center;

      &:before, &:after {
          @include media-breakpoint-up(sm) {
            content: "";
            background: transparent url('../images/ribboend.png') no-repeat center bottom;
            background-size: cover;

          }

          @include media-breakpoint-up(md) {
            flex: 0 0 80px;
          }
          @include media-breakpoint-up(lg) {
            flex: 0 0 93px;
          }
          @include media-breakpoint-up(xl) {
            flex: 0 0 105px;
          }
      }

      &:after {
        @include media-breakpoint-up(sm) {
          -moz-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          filter: FlipH;
          -ms-filter: "FlipH";
        }
      }

      > span, > h1, > p {
        background-color: #A61E1E;
        text-transform: uppercase;
        color: $white;
        padding: 10px;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
        font-weight: $font-weight-normal;
        @include rhythm(16, 18);
        @include media-breakpoint-up(md) {
          width: auto;
          margin-bottom: 9px;
          display: flex;
          align-items: center;
          padding: 5px;
          min-height: 50px;
          @include rhythm(18, 20);
        }
        @include media-breakpoint-up(lg) {
          padding: 7px;
          min-height: 60px;
          @include rhythm(22, 24);
        }
        @include media-breakpoint-up(xl) {
          padding: 10px;
          min-height: 70px;
          @include rhythm(24, 26);
        }

        > span {

          @include media-breakpoint-up(sm) {
            margin-top: -4px;
            min-width: 700px;
          }
        }
      }

    }

    #summerstyle & {

      div {

        &:before, &:after {
            @include media-breakpoint-up(sm) {
              background: transparent url('../images/ribboend_summer.png') no-repeat center bottom;

            }
        }

        > span, > h1, > p {
          background-color: #007a2c;
        }

      }

    }

  }

  &--fpslide {

    @include media-breakpoint-up(md) {
      display: block;
      position:absolute;
      left:0;
      right: 0;
      bottom: 55px;
    }

  }

  &--entry {
    margin: 15px auto;

    @include media-breakpoint-up(sm) {
      margin: 50px auto 30px;
    }
  }

  &--search {
    margin: 15px auto;

    @include media-breakpoint-up(sm) {
      margin: 50px auto 30px;
    }
    div > h1 {
      text-transform: none;
    }
  }


  &--postimage {

    @include media-breakpoint-up(md) {
      display: block;
      position:absolute;
      left:0;
      right: 0;
      bottom: -40px;
    }
  }
  &--slide {

    @include media-breakpoint-up(md) {
      display: block;
      position:absolute;
      left:0;
      right: 0;
      bottom: 20px;
    }
  }


}

// .container {
//   &__balls {
//     @include media-breakpoint-down(lg) {
//       max-width: 100%;
//     }
//   }
// }

.balls {
  padding: 0 0 40px 0;
  text-align: left;
  position: relative;
  z-index: 4;
  word-wrap:break-word;

  &__box {
    @include make-col-ready();
    position: relative;
    font-family: $headings-font-family;
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(xl) {
      @include make-col(12);
    }

    &--wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      background-color: rgba(255,255,255,0.5);
      @include media-breakpoint-up(sm) {
        background-color: transparent;
      }
    }

    &--image {
      // flex-basis: 205px;
      max-width: 150px;
      width: auto;
      z-index: 10;
      @include media-breakpoint-up(sm) {
        max-width: 100%;
        width: 205px;
      }

      img {
        width: 100%;
        max-width: 100%;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        @include media-breakpoint-up(sm) {
          border-radius: 50%;
          border-width: 5px;
          border-style: solid;
          border-color: rgba(255,255,255,0.5);
        }
      }

    }


    &--title {
      min-height: 145px;
      z-index: 9;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      position: relative;
      padding: 10px;
      @include media-breakpoint-up(sm) {
        background-color: rgba(255,255,255,0.5);
        padding-right: 30px;
        padding-top: 20px;
        padding-bottom: 26px;
        padding-left: 80px;
        margin-left: -60px;
      }

      h2 {
        @include rhythm(16,20);
        margin-bottom: 3px;
        margin-top: 0;
        padding-top: 0;
        @include media-breakpoint-up(sm) {
          @include rhythm(22,22);
        }

        &:after {
          content: ">";
          display: inline-block;
          padding-left: 10px;
        }
      }
      p {
        @include rhythm(14,18);
        font-weight: $font-weight-light;
        margin-bottom: 0;
        @include media-breakpoint-up(sm) {
          @include rhythm(16,20);
        }

      }
    }

    .readmore {
      opacity: 0;
      visibility: hidden;
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      display: inline-block;
      border: 3px solid #000;
      @include rhythm (12,12);
      color: $white;
      padding: 4px 8px;
      background-color: #870000;
      position: absolute;
      bottom: 0;
      right: 0;
      @include media-breakpoint-up(sm) {
        bottom: 6px;
        right: -15px;
      }
    }

    a {
      display: block;
      color: $black;
      text-decoration: none;
      // margin-bottom: 4px;
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;

      &:hover, &:focus, &:active {
        text-decoration: none;
        color: $black;

        .readmore {
          opacity: 1;
          visibility: visible;
        }

        .balls__box--title {
          background-color: $white;
          -webkit-transition-duration: 0.4s;
          -moz-transition-duration: 0.4s;
          -o-transition-duration: 0.4s;
          transition-duration: 0.4s;
        }

        img {
          border-color: $white !important;
          -webkit-transition-duration: 0.4s;
          -moz-transition-duration: 0.4s;
          -o-transition-duration: 0.4s;
          transition-duration: 0.4s;

        }
      }

    }

  }

}

.single-video {

  .videorow {
    margin: 0;
  }
  .card {
    margin-bottom: $grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width;
    }
    background-color: rgba(255,255,255,0.6);
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .card-header {
    @include rhythm(20,22);

    p {
      margin: 0;
      padding: 10px 0;
    }
  }
  .card-text {

    p:last-child {
      // @include rhythm(14,18);
      margin: 0;
    }
  }

  .card-body {
    position: relative;

    h2 {
      padding-top: 0;
      @include rhythm(20,24);
    }

  }

}

.singlephoto {

  .card {

    background-color: rgba(255,255,255,0.6);
    color: $black;
    text-align: center;
    border-bottom: none;

    @include media-breakpoint-up(lg) {
      max-height: calc(100vh - 150px);
      overflow-y: hidden;
    }

    .customize-support & {
      @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 192px);
      }

      img {

        @include media-breakpoint-up(lg) {
          max-height: calc(100vh - 314px);
          align-self: flex-start;
        }
      }

    }

    img {
      margin: 0 auto;
      flex: 0 0 auto;
      align-self: flex-start;
      @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 272px);
        // max-height: calc(-272px + 100vh);
        object-fit: scale-down;
        flex: 0 0 auto;
        width: auto;
        align-self: flex-start;
      }
    }
  }

  .card-header {
    @include rhythm(16,20);
    font-weight: $font-weight-normal;
    padding: 1rem;

    .photonav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      align-items: center;

      li {

        &.gplus {
          display: none;
          @include media-breakpoint-up(sm) {
            display: inline;
          }
        }
      }

      .previous-link {
        span {
          display: none;
          @include media-breakpoint-up(lg) {
            display: inline;
          }
        }

        a {
          @include media-breakpoint-down(md) {
            display: inline-block;
            padding: 0 10px;
            text-align: center;
            background-color: #ababab;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            overflow: hidden;
            line-height: 25px;
            margin: 0 5px 0 0;
           color: #fff;
          }
        }
      }

      .next-link {
        span {
          display: none;
          @include media-breakpoint-up(lg) {
            display: inline;
          }
        }

        a {
          @include media-breakpoint-down(md) {
            display: inline-block;
            padding: 0 10px;
            text-align: center;
            background-color: #ababab;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            overflow: hidden;
            line-height: 25px;
            margin: 0 0 0 5px;
           color: #fff;
          }
        }
      }
    }
  }

  .card-footer {
    padding: 0;
    position: relative;
    background-color: hsla(0,0%,100%,.6);
    text-align: center;
    border-left: 1px solid rgba(0,0,0,.125);
    border-right: 1px solid rgba(0,0,0,.125);
    border-bottom: 1px solid rgba(0,0,0,.125);
    margin-bottom: $grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width;
    }

    &--padding {
      padding: 1rem;
    }

    h4 {
      @include rhythm(16,18);
      font-weight: $font-weight-normal;
    }
    .useful-link {
      margin-bottom: 5px;
    }
  }

  .photo-title {
    @include rhythm(18,20);
    margin-bottom: 10px;
    font-weight: $font-weight-normal;
  }

  .photo-text {
    @include rhythm(16,20);
    // font-weight: $font-weight-normal;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-body {
    position: relative;
    padding: 1rem;
    background-color: hsla(0,0%,100%,.6);
    text-align: center;
    border-left: 1px solid rgba(0,0,0,.125);
    border-right: 1px solid rgba(0,0,0,.125);
  }

}
.videolist {

  .card {
    margin-bottom: $grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width;
    }
    background-color: rgba(255,255,255,0.6);
    color: $black;

    &--readmore {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: -40px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      display: inline-block;
      background-color: #8e2323;
      @include rhythm(14,30);
      color: $white;
      padding: 0 15px;
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $black;
      outline: none;

      .card--readmore {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .card-header {
    @include rhythm(18,20);
    font-weight: $font-weight-normal;
    padding: 1rem;
  }

  .card-title {
    @include rhythm(16,18);
    margin-bottom: 5px;
  }

  .card-text {
    @include rhythm(14,18);
    font-weight: $font-weight-light;
    margin-bottom: 0;
  }

  .card-body {
    position: relative;
    padding: 1rem;
  }

  .edit-link {
    position: absolute;
    bottom: 17px;
    right: 20px;
  }

}
.posts {

  .card {
    margin-bottom: $grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width;
    }
    background-color: rgba(255,255,255,0.6);
    color: $black;

    &--readmore {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: -40px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      display: inline-block;
      background-color: #8e2323;
      @include rhythm(14,30);
      color: $white;
      padding: 0 15px;
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $black;
      outline: none;

      .card--readmore {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .card-title {
    @include rhythm(18,20);
    margin-bottom: 5px;
  }

  .card-text {
    @include rhythm(14,20);
    font-weight: $font-weight-light;
    margin-bottom: 0;
  }

  .card-body {
    position: relative;
  }

  .edit-link {
    position: absolute;
    bottom: 17px;
    right: 20px;
  }

}

.ingress {
  @include rhythm(20,24);
  text-align: center;
  margin-bottom: 20px;
  font-weight: $font-weight-normal;
  @include media-breakpoint-up(sm) {
  margin-bottom: 40px;
  }
}


#accordion {
  .card {
    margin-bottom: $grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width;
    }
    background-color: rgba(255,255,255,0.6);
    color: $black;
  }
  .card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: rgba(255,255,255,0.6);
    border-bottom: none;

    &:hover, &:focus {
      background-color: rgba(255,255,255,1);
    }

    button {
      @include rhythm(20,24);
      padding: .75rem 1.25rem;
      width: 100%;
      white-space: normal;
      color: $black;
      text-align: left;

      &:hover, &:focus {
        text-decoration: none;
        color: $black;
      }
    }
  }
}




.nostot-title {
  text-align: center;
  margin: 0 0 20px 0;
}

.nosto {
  transition: background-color 0.6s ease;
  margin-bottom: 30px;
  background-color: #865930;
  padding:15px;

  &.nosto-archive {
    // transition: none;
    // float: left;
    // width: 100%;

    .nosto-otsikko {
      // text-align: left;

    }
  }

  .nosto-summary {
    font-size: 0.9em;
  }

  &:focus, &:hover {
    background-color: #ad7e52;

    // a {
    //   color: #58689b;
    //   &:focus, &:hover {
    //     text-decoration: none;
    //     color: #58689b;
    //   }
    // }

    .nosto-otsikko {
      // text-transform: uppercase;
      // color: #58689b;
    }


  }

  a {
    color: #fff;
    &:focus, &:hover {
      text-decoration: none;
      color: #fff;
    }
  }

  img {
    margin-bottom: 15px;
    width: 100%;
  }

  .nosto-otsikko {
    // transition: color 1s ease;
    // text-transform: uppercase;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    display: table;
    width: 100%;
    padding: 0px;
    @include media-breakpoint-up(md) {
      font-size: 22px;
    }

    span {
      display: table-cell;
      vertical-align: middle;
    }

  }

}



.mobilebreak {

  @media (max-width: 600px) {
    width: 100%;

    .img-responsive {
      width: 100%;
    }
  }


}
